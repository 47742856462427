import React from "react";

function fourlock() {
    return(
        <div className="fourlock">
            <div className="fourblock1">
                <div className="firstlock">
                    <div className="sybl">
                        symbol
                    </div>
                    <h2>Strategies that work</h2>
                    <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A</h4>
                    <h4>consequat nunc id purus pretium sagittis. Nulla ridiculus nullam</h4>
                    <h4> bibendum luctus viverra. Eu pellentesque sem sed platea diam</h4>
                    <h4>dignissim duis purus. </h4>
                    <div className="btnblock">
                        <button id="btn2">Read More<span> ↗ </span></button>
                    </div>

                </div>
                <div className="secondlock">
                    <div className="sybl">
                        symbol
                    </div>
                    <h2>Getting Started</h2>
                    <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A</h4>
                    <h4>consequat nunc id purus pretium sagittis. Nulla ridiculus nullam</h4>
                    <h4> bibendum luctus viverra. Eu pellentesque sem sed platea diam</h4>
                    <h4>dignissim duis purus. </h4>
                    <div className="btnblock1">
                        <button id="btn2">Read More<span> ↗ </span></button>
                    </div>

                </div>

            </div>
            <div className="fourblock2">
                <div className="thirdlock">
                    <div className="sybl">
                        symbol
                    </div>
                    <h2>Personalised Recommendation</h2>
                    <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A</h4>
                    <h4>consequat nunc id purus pretium sagittis. Nulla ridiculus nullam</h4>
                    <h4> bibendum luctus viverra. Eu pellentesque sem sed platea diam</h4>
                    <h4>dignissim duis purus. </h4>
                    <div className="btnblock">
                        <button id="btn2">Read More<span> ↗ </span></button>
                    </div>

                </div>
                <div className="fourthlock">
                    <div className="sybl">
                        symbol
                    </div>
                    <h2>Campaign Management</h2>
                    <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A</h4>
                    <h4>consequat nunc id purus pretium sagittis. Nulla ridiculus nullam</h4>
                    <h4> bibendum luctus viverra. Eu pellentesque sem sed platea diam</h4>
                    <h4>dignissim duis purus. </h4>
                    <div className="btnblock">
                        <button id="btn2">Read More<span> ↗ </span></button>
                    </div>

                </div>




                
            </div>







        </div>
    )
}

export default fourlock;