import React, { useState } from 'react';
import barterpic from './assets/barter.png';

function Header() {
    // State to manage dropdown visibility
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // Function to toggle the dropdown
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className="Header">
            <img className="Header-image" src={barterpic} alt="barter-pic"></img>
            <div className="Content">
                <div>Home</div>
            </div>
            <div className="Content">
                <div>Who we are</div>
            </div>
            <div className="Content">
                <div>Expertice</div>
            </div>
            <div className="Content">
                <div>Services</div>
            </div>
            <div className="Content">
                <div>Creator</div>
            </div>
            <div className="Content">
                <div></div>
            </div>
            <div className="Content">
                <div className="Login" onClick={toggleDropdown}>
                    LOGIN
                    {isDropdownOpen && (
                        <div className="Dropdown">
                            <a href="https://brand.onlybarter.in" target="_blank" rel="noopener noreferrer">
                                Brand
                            </a>
                            <a href="https://portal.onlybarter.in" target="_blank" rel="noopener noreferrer">
                                Influencer
                            </a>
                        </div>
                    )}
                </div>
            </div>
            <div className="Button">
                <button id="btn">SET DETAIL</button>
            </div>
            <div className="Symbol">
                <div><i className="uil uil-setting"></i></div>
            </div>
        </div>
    );
}

export default Header;
