import React from "react";

function dovnline() {
    return(
        <div className="dovnline">
            <div className="down1">
                <h2>2022. All Rights Reserved</h2>
            </div>
            <div className="down2">
                <h2>Terms & Conditions Privacy</h2>
            </div>
            <div className="down3">
                <div className="twit">
                <i class='bx bxl-twitter'></i>
                </div>
                <div className="febbk">
                <i class='bx bxl-facebook'></i>
                </div>
                <div className="serchh">
                <i class='bx bxl-dribbble'></i>
                </div>
                <div className="gittb">
                <i class='bx bxl-github'></i>
                </div>
            </div>
        </div>
    )

}
export default dovnline;