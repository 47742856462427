import React from "react";

function clientheadline() {
    return(
        <div className="clientheadline">
            <div className="headliness">
            <h4>Our</h4>
            <h1>Clients</h1>
            </div>
        </div>
    )


}

export default clientheadline;