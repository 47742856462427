import React from "react";

function blogline() {
    return(
        <div className="blogline">
        <h1>Blog From Insights</h1>
        </div>
    )
}

export default blogline