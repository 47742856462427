import React from "react";


function servicesbtn() {
    return(
        <div className="servicesbtn">
        <div className="serviceline">
            <h1>Services</h1>
            <h2>Provided By Us</h2>
        </div>
        <div className="servicebutton">
            <button id="btn">See All <span> ↗ </span></button>
        </div>
        </div>
    )
}

export default servicesbtn;