import React from "react"; 

function siglast() {
    return(
        <div className="singlast">
            <div className="onedev">
                <div className="sighead">
                    <h3>Sign Up to Receive Product Updates and More</h3>
                </div>
                <div className="signhead2">
                    <div className="own1">
                        <h3><span>youremail@gmail.com</span></h3>
                    </div>
                    <div className="own2">
                        <h3>›</h3>
                    </div>
                </div>
            </div>
            <div className="twodev">
                <h2>Office</h2>
                <h3>545, Street 11, Block F</h3>
                <h3>California, U.S.A</h3>
            </div>
            <div className="threedev">
                <h2>Contact</h2>
                <h3>+92 302 300 3215</h3>
                <h3>ouraddress@email.com</h3>
            </div>


        </div>
    )
}

export default siglast;