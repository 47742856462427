import React from "react"

function Rateaudience() {
    return(
        <div className="rateaudience">
          <div className="content">
            <div className="content1">
                <h2>54.8k</h2>
            </div>
            <div className="content1">
                <h3>Content Creators</h3>
            </div>
          </div>
          <div className="content">
            <div className="content2">
                <h2>12.5k</h2>
            </div>
            <div className="content2">
                <h3>Audience Tracked</h3>
            </div>
          </div>
          <div className="content">
            <div className="content3">
                <h2>135 m+</h2>
            </div>
            <div className="content3">
                <h3>Post Measured</h3>
            </div>
          </div>

        </div>
    )

}
export default Rateaudience;