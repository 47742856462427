import React from "react"

function Lastheadline() {
    return(
        <div className="lastheadline">
          <h2><span>Trusted</span> by the online video industry for insights</h2>
          <h3>&measurement</h3>
        </div>
    )

}
export default Lastheadline;



