import Header from './Header.jsx'
import Herosec from './Herosec.jsx'
import Herosectwo from './Herosectwo.jsx'
import Herosecthree from './Herosecthree.jsx'
import Herosecfour from './Herosecfour.jsx'
import Lastheadline from './Lastheadline.jsx'
import Rateaudience from './Rateaudience.jsx'
import Servicesbtn from './Servicesbtn.jsx'
import Fourlock from './Fourlock.jsx'
import Clientheadline from './Clienheadline.jsx'
import Clienparagrap from './Clienparagrap.jsx'
import Blogline from './Blogline.jsx'
import Blopicture from './Blopicture.jsx';
import Siglast from './Siglast.jsx';
import Dovnline from './Dovnline.jsx'



function App() {
    return(
        <div>
            <Header/>
            <Herosec/>
            <Herosectwo/>
            <Herosecthree/>
            <Herosecfour/>
            <Lastheadline/>
            <Rateaudience/>
            <Servicesbtn/>
            <Fourlock/>
            <Clientheadline/>
            <Clienparagrap/>
            <Blogline/>
            <Blopicture/>
            <Siglast/>
            <Dovnline/>
        
            
        </div>
        
    );
}

export default App